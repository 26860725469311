<template>
  <v-card flat v-if="$route.name!='Dashboard'">
    <v-sheet min-height="20"/>
    <v-row class="short">
      <v-col cols="1"/>
      <v-col cols="10">
        <v-text-field
          v-model="email"
          :value="email"
          label="Email Address"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row class="short">
      <v-col cols="1"/>
      <v-col cols="10">
        <v-text-field
          v-model="password"
          :value="password"
          label="Password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5"/>
      <v-btn @click="login" color="blue" dark> Login </v-btn>
    </v-row>
    <v-card-actions>
      Don't have an account yet? &nbsp;
      <router-link class="link_text" to="/register">
        Register
      </router-link>
    </v-card-actions>
  </v-card>
  <v-card
    flat
    v-else
  >
    <v-btn to="/dashboard" color="blue" width="150" dark> Dashboard </v-btn>
    <v-sheet min-height="5"/>
    <v-btn @click="logout" color="blue" width="150" dark> Sign out </v-btn>
  </v-card>
</template>

<script>
import { Auth } from 'aws-amplify';
export default {
  data(){
    return{
      email: '',
      password: '',
      show: false
    };
  },
  methods: {
    async login() {
      try {
        await Auth.signIn(this.email.trim(), this.password);
        this.$router.push("dashboard");
      } catch (error) {
        alert(error.message);
      }
    },
    async logout() {
      try {
        await Auth.signOut();
        this.$router.push("/");
      } catch (error) {
        alert(error.message);
      }
    },
  }
}
</script>

<style>
.short{
  max-height: 60px;
}
</style>
