import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import vuetify from './plugins/vuetify'
Amplify.configure(awsmobile);
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
