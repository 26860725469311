<template>
  <div id="app">
    <v-app>
      <div>
        <v-app-bar color="blue darken-3" dark>
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

          <v-toolbar-title>
            <router-link class="link_text" style="color: white" to="/">
              NETWORK ANALYZER DEMO
            </router-link>
          </v-toolbar-title>

          <v-spacer />

          <div class="text-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="$route.name!='Dashboard'"
                  v-bind="attrs"
                  v-on="on"
                  dark
                >
                  Log In <v-icon>mdi-login</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  dark
                >
                  Account
                </v-btn>
              </template>

              <v-card>
                <v-list>
                  <Login />
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" absolute temporary>
          <v-list nav dense>
            <v-list-item-group v-model="group">
              <router-link class="link_text" to="/">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Home</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link class="link_text" to="/about">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-information-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>About</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link class="link_text" to="/definitions">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-book-search-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Definitions</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link class="link_text" to="/privacypolicy">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-lock-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Privacy Policy</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link class="link_text" to="/userpolicy">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-human-queue</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>User Policy</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link class="link_text" to="/contact">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-email-fast-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Contact Us</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
      <router-view />
      <v-sheet height="50px" />
      <v-footer
        class="justify-center pl-0"
        height="50px"
        color="grey lighten-2"
        bottom
        padless
        app
        absolute
      >
        <v-spacer />
        <router-link to="/contact"> Contact Us </router-link>
        <a href="https://www.icatalysts.net/" target="_blank">
          <v-img
            :src="footerImg"
            max-height="50"
            width="150"
            contain
          />
        </a>
        <u>Copyright 2023 &copy; iCatalysts</u>
        <v-spacer />
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import Login from "./components/Login.vue";

export default {
  data: () => ({
    drawer: false,
    group: null,
    menu: false,
    showScheduleForm: false,
    footerImg: require("./assets/img/icatalyst.png"),
  }),
  components: {
    Login,
  },
};
</script>

<style>
@import "./assets/styles/main.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.link_text {
  text-decoration: none;
  color: inherit;
}
.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
</style>
