import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/statics/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import('../views/statics/Contact.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/definitions',
    name: 'Definitions',
    component: () => import('../views/statics/Definitions.vue')
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import('../views/statics/PrivacyPolicy.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/userpolicy',
    name: 'User Policy',
    component: () => import('../views/statics/UserPolicy.vue')
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('../views/Verify.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
