<template>
  <div class="home">
    <v-sheet min-height="35" color="blue"/>
    <v-sheet
      color="blue"
      width="100%"
      dark
    >
      <v-row>
        <v-col cols="2"/>
        <v-col cols="8">
          <v-card-title class="text-h3 justify-center">
            <b>
              Analyze your network to strengthen your <br/>
              capacity to achieve your goals
            </b>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"/>
        <v-col cols="8">
          <v-card-title class="text-h5 justify-center">
            Leverage network science to get actionable insight for your organization.
          </v-card-title>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet min-height="35" color="blue"/>
    <v-row>
      <v-col cols="2"/>
      <v-col cols="8">
        <v-carousel
          v-model="carousel"
          show-arrows-on-hover
          hide-delimiters
          cycle
          interval="8000"
        >
          <v-carousel-item
            v-for="item in slides"
            :key="item.index"
          >
            <v-card-title class="text-h3 justify-center">
              {{item.text}}
            </v-card-title>
            <v-img
              :src="item.image"
              height="85%"
              contain
            />
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  data(){
    return{
      carousel: 0,
      slides: [
        { "index": "1",
          "image": require("../assets/img/networkVisual.png"),
          "text": "Analyze network interaction."
        },
        { "index": "2",
          "image": require("../assets/img/mapVisual.png"),
          "text": "Visualize geographical diversity."
        },
        { "index": "3",
          "image": require("../assets/img/newFiltersVisual.png"),
          "text": "Map startup clusters by county."
        }
      ]
    }
  }
}
</script>
