/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:a344004d-4c56-4c72-9172-9aa69f1e1229",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_jrxztJ7bN",
    "aws_user_pools_web_client_id": "7vjokcej9qupaslvsm1djvmr7e",
    "oauth": {}
};


export default awsmobile;
